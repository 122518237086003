import react from "react";
import Header from "../components/Header";

const BecasLaboratorio = () => {
  return (
    <body className="about-us">
      <Header navStyle="transparent" />
      <div
        className="page-header header-filter header-small"
        data-parallax="true"
        style={{ backgroundImage: `url('https://exactas.uba.ar/wp-content/uploads/2019/03/DSC_0061bW.jpg')` }}>
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-md-offset-2">
              <h1 className="title">Becas de Materiales de Laboratorio</h1>
            </div>
          </div>
        </div>
      </div>

      <div className="main main-raised">
        <div className="container">
          <div className="about-description text-center">
            <div className="row">
              <div className="col-md-10 col-md-offset-1">
                <p className="description cecen-texto">
                El CECEN ofrece becas para materiales de laboratorio para utilizar en los locales del Pabellón 2. <br /> Se asignan según vulnerabilidad económica. <br /> La convocatoria abre a principios de cada cuatrimestre, <strong>te vas a poder anotar en este forms:</strong>

                </p>
                <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSdONULuO8ebPOdQ7GTUI84KvDjWF1OAKiuBKBE8jYysfoEgJA/viewform?embedded=true" width="640" height="9300" frameborder="0" marginheight="0" marginwidth="0">Cargando…</iframe>              </div>
            </div>
          </div>


         
        </div>
      </div>
    </body>
  );
};

export default BecasLaboratorio;
