import React, { useContext, useState } from "react";
import Header from "../components/Header";
import InstagramFeed from "../components/home/InstagramFeed";
import Footer from "../components/Footer";
import { BotoneraHome } from "../components/home/BotoneraHome";
import { showBotButton } from "../components/home/showBotButton";
import { Link, animateScroll as scroll } from "react-scroll";
import { UserContext } from "../contexts/UserContext";
import { BotoneraSingle } from "../components/home/BotoneraSingle";

export function Home() {
  const [showBot, setShowBot] = useState(false);

  const [usuario, setUsuario] = useState("");
  const [pass, setPass] = useState("");

  const { user, setUser } = useContext(UserContext);

  const API_URL = 'https://admin.cecen.ar/api/';
  // const API_URL = 'http://127.0.0.1:8000/api/';

  const handleLogin = async () => {
    const data = { dni: usuario, password: pass };

    fetch(API_URL+'usuario/login' , {
      method: "POST",
      body: JSON.stringify(data),
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => response.json())
      .then((a) => {
        console.log(a)
        if ( !a.login ){
          alert(a.msg)
        }else{
          const data = a.dataUsuario[0];
          setUser(data);
          localStorage.setItem("userData", JSON.stringify(data));
        }

      });
  };

  const generate_credential = () => {
    
    const data_credencial = {
      nombre: user.Nombre + " " + user.Apellido,
      dni: user.DNI,
    }
    if (user.Libreta == null){
      data_credencial.lu = ""
    }else{
      data_credencial.lu = user.Libreta
    }
    let link_credencial = document.getElementById("link_credencial")
    link_credencial.innerHTML = '<i class="fa-solid fa-spinner"></i> Generando la credencial ..'

    fetch(API_URL+'generar-credencial', {
      method: "POST",
      body: JSON.stringify(data_credencial),
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => response.blob())
      .then((file) => {
        const a = document.createElement('a');
        a.setAttribute('download', 'MiCECEN - ' + user.Nombre + " " + user.Apellido);
        a.setAttribute('target', '_blank')
        a.setAttribute('href', window.URL.createObjectURL(file));
        a.click(); // EXECUTING CLICK EVENT WILL AUTO-DOWNLOAD THE FILE
        link_credencial.innerHTML = '<i class="fa-solid fa-check"></i> Gracias por descargar MiCECEN'
      });

  }

  return (
    <div className="App">
      <Header navStyle="mainColor" />
      {/* {subHeader} */}

  
      <div className="parallax hidden-xs" style={{backgroundImage:"url('https://www.argentina.gob.ar/sites/default/files/_dsc5325.jpg')" }}>
        <div className="overlay"></div>

        <div className="content">
          <img
            className="isoLogoHome animate__animated animate__pulse"
            src="assets/img/cecen-isologo.png"
            alt="CECEN - Centro de Estudiantes de la Facultad de Ciencias Exactas y Naturales"
          />
          <h1 className="animate__animated animate__pulse">
            Bienvenidxs al Nuevo Cecen
          </h1>
          <h2 className="animate__animated animate__pulse">
          Centro de Estudiantes de la Facultad de Ciencias Exactas y Naturales - UBA
          </h2>
          {/* <p>Nos organizamos para velar por nuestros derechos estudiantiles y conseguir nuevos, para defender la educación pública gratuita, masiva y de calidad, para pelear por una ciencia al servicio del pueblo y para aportar a la construcción de un país (y un mundo) más inclusivo.</p> */}
        </div>
        <Link
          smooth={true}
          offset={0}
          duration={400}
          to="mainHomeContent"
          className="animate__animated animate__bounce downArrow"
        >
          <i className="fas fa-chevron-down"></i>
        </Link>

        {/* CONTENIDO DE LA FOTO PRINCIPAL */}
        {/* <video loop="" muted="" autoplay="" class="fullscreen-bg__video" style={{width: "100%;"}}>
              <source src="https://frentedetodos.org/images/loop.mp4" type="video/mp4" />
          </video>         */}
      </div>

      <div className="container" id="mainHomeContent">
        <div className="about-services features-2">
          
          
          <div className="row">
            <div className="col-md-12">
            </div>
          </div>
          {
            user && <>
              <div className="row hidden-sm hidden-md hidden-lg">
                <div className="col-md-12">
                  <BotoneraSingle
                    title="Qué es el CECEN"
                    content="El nuevo CECEN (Centro de Estudiantes de Ciencias Exactas y Naturales) es el órgano gremial que tenemos les estudiantes de la FCEyN."
                    bkg="bkg-violeta"
                    link="nosotrxs"
                    icon="info"
                  />
                </div>
              </div>
            
            </>
          }
          <BotoneraHome />
        </div>
      </div>
      <InstagramFeed />
      <Footer />

    </div>
  );
}
