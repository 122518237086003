import react, { useState, useEffect, useContext } from "react";
import Header from "../components/Header";
import Swal from 'sweetalert2'
import { UserContext } from "../contexts/UserContext";

const RecuperaPassword = () => {

    const API_URL = 'https://admin.cecen.ar/api/'
    // const API_URL = 'http://127.0.0.1:8000/api/';

    const [codigo, setCodigo] = useState("")
    const [dni, setDni] = useState("")
    const [password, setPassword] = useState("")
    const { user, setUser } = useContext(UserContext);

    const [codigoEnviado, setCodigoEnviado] = useState(false)
    const [passCambiada, setPassCambiada] = useState(false)


    const [successRegister, setSuccessRegister] = useState(false)

    const triggerAlert = (type, textButton, titleText, contentText="") => {
        Swal.fire({
            title: titleText,
            text: contentText,
            icon: type,
            confirmButtonText: textButton
        })    
    }

    const pedirCodigo = () => {
        if (dni==""){
            triggerAlert("error", "Tenes que ingresar el DNI con el que te registraste.", "Error")
        }else{
            const data = {
                "dni": dni
            }
            fetch(API_URL+'usuario/generar_codigo_recuperacion', {
                method: "POST",
                body: JSON.stringify(data),
                headers: { "Content-Type": "application/json" },
            })
            .then(response => response.json())
            .then(response => {
                if (response.status=='Success'){
                    setCodigoEnviado(true)
                }else{
                    triggerAlert("error", response.msg, "Error")
                }
            })
        }
    }

    const cambiarContrasena = () => {

        const data = {
            "dni": dni,
            "codigo": codigo,
            "password": password
        }

        fetch(API_URL+'usuario/restaurar_contrasena', {
            method: "POST",
            body: JSON.stringify(data),
            headers: { "Content-Type": "application/json" },
        })
        .then(response => response.json())
        .then(response => {
            console.log(response)
            if (response.status=='Success'){
                setPassCambiada(true)
            }else{
                triggerAlert("error", response.msg, "Error")
            }
        })

    }
    
    return (
        <body className="about-us">
            <Header navStyle="transparent" />

            <div
                className="page-header header-filter header-small"
                data-parallax="true"
                style={{ backgroundImage: `url('https://i.imgur.com/5HlhttN.jpg')` }}
            >
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 col-md-offset-2">
                            <h1 className="title">Recuperá tu contraseña</h1>
                        </div>
                    </div>
                </div>
            </div>

            <div className="main main-raised">
                <div className="container">
                    <div className="about-description text-center">
                        <div className="row">
                            <div className="col-md-10 col-md-offset-1" style={{ marginBottom: 40 }}>
                                <h2 style={{fontWeight:900}}>Recuperá la contraseña - Sistema MiCECEN</h2>
                                <p style={{fontSize:21, lineHeight:1.2}}>
                                MiCECEN es un sistema que te permite anotarte a las becas de trabajo que otorga el CECEN, al igual que a las becas de apuntes y materiales de estudio, y a los sorteos de lockers. Logearte también te permite ver los apuntes de Biblioteca Digital y cargar los tuyos! Además te brinda un descuento en los almuerzos que compres en nuestros locales.
                                </p>

                                {
                                    successRegister &&
                                    <>
                                        <div class="alert alert-success" role="alert">
                                            <i style={{marginRight:10}} className="fa fa-check"></i>
                                            Gracias por registrarte, podes volver al inicio para iniciar sesión con tu usuario.                                        
                                        </div>
                                        <a href="/" class="btn btn-success">Volver</a>

                                    </>
                                }
                                {
                                    !successRegister &&
                                    <>
                                        { !codigoEnviado && 
                                            <div>
                                                <h3>Primero, solicitá un código de verificación que llegará al mail con el que te registraste.</h3>
                                                <div className="form-element">
                                                    <label for="field-nombre"></label>
                                                    <input onChange={(e) => setDni(e.target.value)} placeholder="Ingresá tu DNI" name="field-dni" className="form-control" type={'number'} />
                                                </div>
                                                <a className={"btn btn-success"} onClick={pedirCodigo}>Solicitar código de verificación</a>
                                            </div>
                                        }
                                        { codigoEnviado & !passCambiada && 
                                            <>
                                                <h3>Ahora podes revisar tu email para buscar el código y generar una nueva contraseña.</h3> 
                                                <div className="form-element">
                                                    <label for="field-nombre"></label>
                                                    <input onChange={(e) => setCodigo(e.target.value)} required placeholder="Ingresá tu codigo" name="field-codigo" className="form-control" type={'text'} />
                                                </div>
                                                <div className="form-element">
                                                    <label for="field-nombre"></label>
                                                    <input onChange={(e) => setDni(e.target.value)} placeholder="Ingresá tu DNI" name="field-dni" className="form-control" type={'number'} />
                                                </div>
                                                <div className="form-element">
                                                    <label for="field-password"></label>
                                                    <input onChange={(e) => setPassword(e.target.value)} placeholder="Ingresá una nueva contraseña" name="field-password" className="form-control" type={'password'} />
                                                </div>
                                                
                                                
                                                <div className="form-element" style={{ marginTop: 15, textAlign: 'left' }}>
                                                    <button onClick={cambiarContrasena} style={{ width: '100%' }} type="button" className={"btn btn-info"}>Cambiar contraseña</button>
                                                </div>
                                            </>
                                        }
                                        {
                                            passCambiada &&
                                                <>
                                                    <h3>Tu contraseña fue cambiada con éxito, podes volver al inicio para ingresar.</h3> 
                                                    <a href="/" class="btn btn-success">Volver</a>
                                                </>
                                        }
                                    </>
                                }           
                            </div>
                        </div>
                    </div>




                </div>



            </div>
        </body>
    );
};

export default RecuperaPassword;
