import React from 'react'
import { Link } from "react-router-dom";

function Header(props) {
	const navStyle = props.navStyle;
	let navClass;
	if (navStyle == 'transparent') {
		navClass = 'navbar navbar-transparent navbar-absolute'
	} else if (navStyle == 'mainColor') {
		navClass = 'navbar navbar-primary '
	}

	return (
		<div id="navbar">
			<nav className={navClass}>
				<div className="container">
					<div className="navbar-header" style={{ 'padding': '0' }}>
						<button type="button" className="navbar-toggle" data-toggle="collapse" data-target="#example-navbar-primary">
							<span className="sr-only">Toggle navigation</span>
							<span className="icon-bar"></span>
							<span className="icon-bar"></span>
							<span className="icon-bar"></span>
						</button>

						<div className="logo-header" style={{ 'width': '150px', 'float': 'left' }}>
							<a className="navbar-brand" href="/" style={{ 'padding': '0' }}><img style={{ 'width': '100%', 'float': 'left' }} src="assets/img/logo-blanco.png" /></a>
						</div>
					</div>

					<div className="collapse navbar-collapse" id="example-navbar-primary">
						<ul className="nav navbar-nav navbar-right">
							<li>
								<Link to="/nosotrxs">
									<i className="material-icons" style={{ paddingRight: '10px', }}>explore</i>
									Qué es el CECEN
								</Link>
							</li>

							<li>
								<a target="_blank" href="https://www.instagram.com/cecen.exactas">
									<i className="fab fa-instagram"></i>
								</a>
							</li>
							<li>
								<a target="_blank" href="https://www.youtube.com/CecenUba">
									<i className="fab fa-youtube"></i>
								</a>
							</li>
							<li>
								<a target="_blank" href="https://www.facebook.com/CECEN.UBA">
									<i className="fab fa-facebook"></i>
								</a>
							</li>
						</ul>
					</div>
				</div>
			</nav>
		</div>
	);
}

export default Header;