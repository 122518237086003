
const Footer = () => {
    return (
        <footer className="footer">
            <div className="container">
                <nav className="pull-left">
                    <ul>
                        <li>
                            <a href="nosotrxs">
                                Qué es el CECEN
                            </a>
                        </li>
                    </ul>
                </nav>
                <div className="copyright pull-right">
                    2024, la web del Nuevo Cecen <i className="fa fa-heart heart"></i>
                </div>
            </div>
        </footer>
    )
}

export default Footer;