import React, { useEffect, useMemo, useState } from "react";

import "./App.css";
import "./css/Header.css";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

/* SCREENS */
import CecenInfo from "./screens/CecenInfo";
import Becas from "./screens/Becas";
import Locales from "./screens/Locales";

import BiblioCen from "./screens/Bibliocen";
import { Home } from "./screens/Home";
import { UserContext } from "./contexts/UserContext";
import RegistroUsuario from "./screens/RegistroUsuario";
import Finales from "./screens/Finales";
import Cbc from "./screens/Cbc";
import InfoGeneral from "./screens/InfoGeneral";
import RecuperaPassword from "./screens/RecuperaPassword";
import Lockers from "./screens/Lockers";
import BecasTrabajo from "./screens/BecasTrabajo";
import BecasMateriales from "./screens/BecasMateriales";
import BecasLaboratorio from "./screens/BecasLaboratorio";

function App() {

  const [user, setUser] = useState(false);
  const providerValue = useMemo( 
    () => ({ user, setUser }), 
    [user]
  );

  useEffect(() => {
    let user = localStorage.getItem("userData");
    if(user!='undefined'){
      user = JSON.parse(user)
      setUser(user);
    }
  }, [])

  return (
    <Router>
      <div>
        <Switch>
          <UserContext.Provider value={providerValue}>
            <Route exact path="/becas-laboratorio">
              <BecasLaboratorio />
            </Route>
            <Route exact path="/becas-apuntes">
              <BecasMateriales />
            </Route>
            <Route exact path="/becas-trabajo">
              <BecasTrabajo />
            </Route>
            <Route exact path="/recupera-password">
              <RecuperaPassword />
            </Route>
            <Route exact path="/pedido-lockers">
              <Lockers />
            </Route>
            <Route exact path="/infogeneral">
              <InfoGeneral />
            </Route>
            <Route exact path="/cbc">
              <Cbc />
            </Route>
            <Route exact path="/finales">
              <Finales />
            </Route>
            <Route exact path="/registro">
              <RegistroUsuario />
            </Route>
            <Route exact path="/locales">
              <Locales />
            </Route>
            <Route exact path="/becas">
              <Becas />
            </Route>
            <Route exact path="/nosotrxs">
              <CecenInfo />
            </Route>
            <Route exact path="/bibliotecadigital">
              <BiblioCen />
            </Route>
            <Route exact path="/">
              <Home />
            </Route>
          </UserContext.Provider>
        </Switch>
      </div>
    </Router>
  );
}

export default App;





