import react, { useState, useEffect } from "react";

import Header from "../components/Header";
import Resultados from "../components/bibliocen/Resultados";
import Footer from "../components/Footer";
import FormularioPorCarrera from "../components/bibliocen/FormularioPorCarrera";

const BiblioCen = () => {
  const [tipoBusqueda, setTipoBusqueda] = useState("");
  const [triggeredSearch, setTriggeredSearch] = useState(false);
  const [apuntes, setApuntes] = useState(false);

  const triggerSearch = async (materia) => {
    const url = "https://admin.cecen.ar/api/apunte/materia/" + materia;

    try {
      const response = await fetch(url);
      const json = await response.json();
      setApuntes(json);
    } catch (error) {
    }
    setTriggeredSearch(true);
  };

  useEffect(() => { }, []);

  return (
    <>
      <div className="about-us product-page">
        <Header navStyle="transparent" />
        <div
          className="page-header header-filter"
          data-parallax="true"
          filter-color="cecenvioleta"
          style={{
            minHeight: "50vh",
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundImage: `url('https://exactas.uba.ar/wp-content/uploads/2019/03/DSC_0061bW.jpg')`,
          }}
        ></div>

        <div className="section section-gray">
          <div className="container">
            <div className="main main-raised main-product">
              <div className="row">
                <div className="col-md-12 col-sm-12">
                  <FormularioPorCarrera buscar={triggerSearch} />

                  {triggeredSearch && <Resultados apuntes={apuntes} />}
                </div>
              </div>
            </div>

            <div className="features text-center">
              <div className="row">
                <div className="col-md-4">
                  <div className="info">
                    <div className="icon icon-info">
                      <i className="material-icons">cloud_upload</i>
                    </div>
                    <h4 className="info-title">Subí tu apunte</h4>
                    <p>
                      Mandanos un mail con tu material a elcecen@gmail.com para colaborar con la Biblioteca Digital. 
                    </p>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="info">
                    <div className="icon icon-success">
                      <i className="material-icons">verified_user</i>
                    </div>
                    <h4 className="info-title">Chequeado</h4>
                    <p>
                      El contenido subido está en permanente
                      revisión, si encontrás algún error o tenes alguna
                      consulta, podes escribirnos por email a elcecen@gmail.com específicando en qué
                      contenido.
                    </p>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="info">
                    <div className="icon icon-rose">
                      <i className="material-icons">favorite</i>
                    </div>
                    <h4 className="info-title">Colaborá</h4>
                    <p>Este es un espacio colaborativo, por lo cual necesitamos el aporte de todes para ampliarlo cada vez más.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default BiblioCen;
