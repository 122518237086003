import react, { useState, useEffect, useContext } from "react";
import Header from "../components/Header";
import Swal from 'sweetalert2'
import { UserContext } from "../contexts/UserContext";
import { Redirect } from "react-router-dom";

const Lockers = () => {

    const API_URL = 'https://admin.cecen.ar/api/'
    // const API_URL = 'http://127.0.0.1:8000/api/';

    const [pabellon, setPabellon] = useState("")
    const [mostrarPantalla, setMostrarPantalla] = useState(true)
    const { user, setUser } = useContext(UserContext);

    const [successRegister, setSuccessRegister] = useState(false)
    const [successMessage, setSuccessMessage] = useState("")

    const triggerAlert = (type, textButton, titleText, contentText = "") => {
        Swal.fire({
            title: titleText,
            text: contentText,
            icon: type,
            confirmButtonText: textButton
        })
    }

    // useEffect(() => {
    //     if(!user){
    //         setMostrarPantalla(false)
    //     }
    // })

    const pedirLocker = () => {
        
        if(pabellon==""){
            triggerAlert("error", "Tenés que elegir un pabellón", "Error")
            
        }else{

            const data = {
                "dni": user.DNI,
                "pabellon": pabellon,
            }
    
            fetch(API_URL + 'usuario/quiero_locker', {
                method: "POST",
                body: JSON.stringify(data),
                headers: { "Content-Type": "application/json" },
            })
                .then(response => response.json())
                .then(response => {
                    console.log(response)
                    if (response.status == 'Success') {
                        setSuccessRegister(true)
                        setSuccessMessage(response.msg)
                    } else {
                        triggerAlert("error", response.msg, "Error")
                    }
                })

        }

        

    }
    // if (!mostrarPantalla){
    //     return <Redirect to="/" />
    // }else{

        return (
            <body className="about-us">
                <Header navStyle="transparent" />

                <div
                    className="page-header header-filter header-small"
                    data-parallax="true"
                    style={{ backgroundImage: `url('https://i.imgur.com/5HlhttN.jpg')` }}
                >
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8 col-md-offset-2">
                                <h1 className="title">Solicita un Locker</h1>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="main main-raised">
                    <div className="container">
                        <div className="about-description text-center">
                            <div className="row">
                                <div className="col-md-10 col-md-offset-1" style={{ marginBottom: 40 }}>
                                    <h2 style={{ fontWeight: 900 }}>Lockers - Sistema MiCECEN</h2>
                                    <p style={{ fontSize: 21, lineHeight: 1.2 }}>
                                        Los lockers sirven para guardar cosas que usamos siempre en la facu (y no queremos traerlas todos los días). Para conseguir el tuyo tenés que anotarte, elegir tu pabellón y estar atento a los resultados del sorteo.
                                    </p>

                                    {
                                        successRegister &&
                                        <>
                                            <div class="alert alert-success" role="alert">
                                                <i style={{ marginRight: 10 }} className="fa fa-check"></i>
                                                {successMessage}
                                            </div>
                                            <a href="/" class="btn btn-success">Volver</a>

                                        </>
                                    }
                                    {
                                        !successRegister &&
                                        <>
                                            <div>
                                                <h3>Elegí un pabellón para poder participar del sorteo de Lockers del Nuevo CECEN</h3>
                                                <div className="form-element">
                                                    <label for="field-nombre"></label>
                                                    <select onChange={(e) => setPabellon(e.target.value)} name="field-pabellon" className="form-control">
                                                        <option value="#">Elegí tu pabellón</option>
                                                        <option value="Uno">Pabellón 1</option>
                                                        <option value="Dos">Pabellón 2</option>
                                                        <option value="Ind">Pabellón de industrias</option>
                                                    </select>
                                                </div>
                                                <a className={"btn btn-success"} onClick={pedirLocker}>Enviar solicitud</a>
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </body>
        );
    // }

};

export default Lockers;
