import react from "react";
import Header from "../components/Header";

const Becas = () => {
  return (
    <body className="about-us">
      <Header navStyle="transparent" />
      <div
        className="page-header header-filter header-small"
        data-parallax="true"
        style={{ backgroundImage: `url('https://i.imgur.com/5HlhttN.jpg')` }}>
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-md-offset-2">
              <h1 className="title">Becas</h1>
            </div>
          </div>
        </div>
      </div>

      <div className="main main-raised">
        <div className="container">
          <div className="about-description text-center">
            <div className="row">
              <div className="col-md-10 col-md-offset-1">
                <p className="description cecen-texto">
                Todos los meses el CECEN otorga becas para materiales de laboratorio y de apuntes y útiles, canjeables en nuestros locales. También se hacen llamados a becas de trabajo los locales. La próxima convocatoria se abre a principios del 1er cuatrimestre, la información va a estar disponible en esta página.
                </p>
              </div>
            </div>
          </div>

          {/* BECAS DEL CECEN */}
          <div className="row">
            <div className="container">
              <div className="about-services features-2">
                <div className="row">
                  <div className="col-md-8 col-md-offset-2 text-center">
                    <h2 style={{marginBottom: 0}} className="title">Becas del CECEN</h2>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-xs-12">
              <div className="card" style={{ padding: 0 }}>
                <div className="card-content beca content-success bkg-verde">
                  <h5 className="category-social">
                    <i className="fa fa-vial"></i> Becas de Materiales de laboratorio
                  </h5>
                  <p className="card-description">
                  Con estas becas vas a poder comprar materiales de laboratorio que se venden en las librería del CECEN.
                  </p>
                </div>
              </div>

              <div className="card" style={{ padding: 0 }}>
                <div className="card-content beca content-success bkg-violeta">
                  <h5 className="category-social">
                    <i className="fa fa-store"></i> Becas de Trabajo en Locales
                  </h5>
                  <p className="card-description">
                  Existen becas de trabajo en los locales del CECEN para aquelles estudiantes que requieran un trabajo que sea compatible con la cursada.                  
                  </p>
                </div>
              </div>
              
            </div>
            
            <div className="col-md-6 col-xs-12">
              <div className="card" style={{ padding: 0 }}>
                <div className="card-content beca content-success bkg-verde">
                  <h5 className="category-social">
                    <i className="fa fa-tree"></i> Beca de Apuntes y Útiles
                  </h5>
                  <p className="card-description">
                  Estas becas te van a permitir acceder a útiles e impresiones de manera gratuita en los diferentes locales del CECEN.                  </p>
                </div>
              </div>
            </div>
            
          </div>

          {/* BECAS EXTERNAS */}
          <div className="row">
            <div className="container">
              <div className="about-services features-2">
                <div className="row">
                  <div className="col-md-8 col-md-offset-2 text-center">
                    <h2 style={{marginBottom: 0}} className="title">Becas Externas</h2>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xs-12 col-md-6">
              <div className="card" style={{ padding: 0, boxShadow: 'none' }}>
                <div className="card-content beca">
                  <h5 className="category-social">
                    <i className="fa-solid fa-money-bill"></i> <a href="https://exactas.uba.ar/becas-para-estudio" style={{color: '#000'}} target={"_blank"}>Becas FCEyN</a>
                  </h5>
                  <p className="card-description">
                  La Facultad ofrece becas de transporte, de almuerzo y becas de material de estudio, todo dentro de la beca Exactas. 
                  <br/>Además otorga la <strong>Beca Sadosky</strong>. 
                  <br/><strong>Requisito:</strong> ser estudiante de la facultad con el CBC terminado.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-xs-12 col-md-6">
              <div className="card" style={{ padding: 0, boxShadow: 'none' }}>
                <div className="card-content beca">
                  <h5 className="category-social">
                    <i className="fa-solid fa-money-bill"></i> <a href="https://exactas.uba.ar/becas-para-estudio" style={{color: '#000'}} target={"_blank"}>Becas UBA</a>
                  </h5>
                  <p className="card-description">
                  La UBA ofrece la beca Sarmiento.                  
                  <br/><strong>Requisitos:</strong>  ser estudiante de grado de la universidad, ser menor de 30 años y tener promedio superior a 5.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-xs-12 col-md-6">
              <div className="card" style={{ padding: 0, boxShadow: 'none' }}>
                <div className="card-content beca">
                  <h5 className="category-social">
                    <i className="fa-solid fa-money-bill"></i> <a href="https://becasprogresar.educacion.gob.ar/" style={{color: '#000'}} target={"_blank"}>Becas Progresar</a>
                  </h5>
                  <p className="card-description">
                    Monto variable según la carrera y el año de cursada                  
                  <br/><strong>Requisitos:</strong>  ser Argentine native o naturalizade o extranjere con residencia legal de 5 años en el país y DNI, ser estudiante regular, no tener ingresos superiores a 3 salarios mínimos, vitales y móviles en el grupo familiar.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-xs-12 col-md-6">
              <div className="card" style={{ padding: 0, boxShadow: 'none' }}>
                <div className="card-content beca">
                  <h5 className="category-social">
                    <i className="fa-solid fa-money-bill"></i> <a href="https://www.argentina.gob.ar/educacion/becas/becas-manuel-belgrano" style={{color: '#000'}} target={"_blank"}>Becas Manuel Belgrano</a>
                  </h5>
                  <p className="card-description">
                  Hay algunas carreras para las que no está disponible.                
                  <br/><strong>Requisitos:</strong>  Ser argentine native o naturalizade con DNI, tener entre 18 y 30 años para ingresantes, o hasta 35 años para estudiantes cursantes hasta 35 años, no tener ingresos superiores al 3 salarios mínimos, vitales y móviles en el grupo familiar.
                  </p>
                </div>
              </div>
            </div>
            
          </div>
        </div>
      </div>
    </body>
  );
};

export default Becas;
