import react from "react";
import Header from "../components/Header";

const Cbc = () => {
  return (
    <body className="about-us">
      <Header navStyle="transparent" />
      <div
        className="page-header header-filter header-small"
        data-parallax="true"
        style={{ backgroundImage: `url('https://exactas.uba.ar/wp-content/uploads/2019/03/DSC_0061bW.jpg')` }}>
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-md-offset-2">
              <h1 className="title">El Nuevo CECEN en el CBC</h1>
            </div>
          </div>
        </div>
      </div>

      <div className="main main-raised">
        <div className="container">
          <div className="about-description text-center">
            <div className="row">
              <div className="col-md-10 col-md-offset-1">
                <p className="description cecen-texto">
                Todas las becas del Nuevo CECEN incluyen a les estudiantes que están transitando el CBC pero además, dentro del centro funciona 
                un espacio de tutorías gratuitas dadas por estudiantes más avanzades que se llama Remando en el CBC. En la presencialidad, 
                esta instancia funciona casi todos los días en distintos horarios y su espacio físico es el SUM. 
                <br />
                Están en Ig! <a href="https://www.instagram.com/remandoenelcbc" target={"_blank"}>@remandoenelcbc</a>
                </p>
              </div>
            </div>
          </div>


         
        </div>
      </div>
    </body>
  );
};

export default Cbc;
