import react, { useState, useEffect } from 'react'

const FormularioPorCarrera = (props) => {

    const [carreras, setCarreras] = useState([])
    const [materia, setMateria] = useState([])
    const [materias, setMaterias] = useState([])
    const [carreraElegida, setCarreraElegida] = useState('')

    useEffect(() => {
        const url = "https://admin.cecen.ar/api/dame_carreras";

        async function fetchData() {
            try {
                const response = await fetch(url);
                const json = await response.json();
                setCarreras(json)

            } catch (error) {
                console.log("error", error);
            }
        };

        fetchData();
    }, []);

    async function getMaterias(carrera) {
        const url = "https://admin.cecen.ar/api/dame_materias/carrera/" + carrera;

        try {
            const response = await fetch(url);
            const json = await response.json();
            return json
        } catch (error) {
            console.log("error", error);
        }
    };

    const handleCarreraChange = async (e) => {
        const idCarrera = e.target.value
        setCarreraElegida({
            'id': idCarrera,
            'nombre': e.nativeEvent.target[e.nativeEvent.target.selectedIndex].text
        })
        const materias = await getMaterias(idCarrera)
        setMaterias(materias)
    }

    const selectMateria = async (e) => {
        setMateria(e.target.value)
    }

    return (
        <div className="wizard-container" style={{ paddingTop: 0 }}>
            <div className="card wizard-card" style={{ boxShadow: 'none' }} data-color="green" id="wizardProfile">
                <form action="" method="">
                    <div className="wizard-header" style={{ paddingBottom: 0 }}>
                        <h3 style={{ fontSize: '3em' }} className="wizard-title">Biblioteca Digital</h3>
                        {/* <h5>Lorem ipsum</h5> */}
                    </div>
                    <div className="tab-content" style={{ marginTop: 0, marginBottom: '0', minHeight: 'none!important' }}>
                        <div id="carrera">
                            <h4 className="info-text">
                                Es la biblioteca digital del Nuevo CECEN. Acá vas a encontrar parciales y finales viejos, apuntes y mucho más! 
                                <br /> Es un espacio colaborativo, para sumar tus archivos, escribinos a elcecen@gmail.com</h4>
                            <div className="row">
                                <div className="col-sm-10 col-sm-offset-1">
                                    {carreras != [] &&
                                        <select
                                            onChange={handleCarreraChange}
                                            style={{ width: '100%', height: '50px' }} data-style="btn btn-round" title="Elegí la carrera" >
                                            <option value="#">Elegí la carrera</option>

                                            {
                                                carreras.map((c) => { return <option value={c.id} key={c.id}>{c.nombre}</option> })
                                            }

                                        </select>
                                    }

                                    {materias.length > 0 &&
                                        <select
                                            onChange={selectMateria}
                                            style={{ marginTop: '20px', width: '100%', height: '50px' }}
                                            title="Elegí la materia">
                                            <option value="#">Elegí la materia</option>
                                            {materias.map((m) => ('id' in m) ? <option value={m.id} key={m.id}>{m.nombre}</option> : '')}
                                        </select>
                                    }

                                </div>
                                <div className="col-xs-12 col-sm-offset-1 col-sm-10">
                                    <input onClick={() => props.buscar(materia)} type='button' className='btn btn-success' style={{ float: 'right', width: '100%' }} name='buscar' value='Buscar' />
                                </div>
                            </div>
                        </div>
                    </div>

                </form>
            </div>
        </div>
    )
}

export default FormularioPorCarrera;