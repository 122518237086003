import react from "react";
import Header from "../components/Header";

const Finales = () => {
  return (
    <body className="about-us">
      <Header navStyle="transparent" />
      <div
        className="page-header header-filter header-small"
        data-parallax="true"
        style={{ backgroundImage: `url('https://exactas.uba.ar/wp-content/uploads/2019/03/DSC_0061bW.jpg')` }}>
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-md-offset-2">
              <h1 className="title">Prepará finales con el Nuevo Cecen</h1>
            </div>
          </div>
        </div>
      </div>

      <div className="main main-raised">
        <div className="container">
          <div className="about-description text-center">
            <div className="row">
              <div className="col-md-10 col-md-offset-1">
                <p className="description cecen-texto">
                Todos los años, para las fechas de final de febrero/marzo, julio/agosto y diciembre, les estudiantes más avanzades ayudan a estudiantes iniciales a preparar sus primeros finales. En esta página vas a encontrar la info de las próximas fechas.
                </p>
                <p className="description cecen-texto">
                Si querés sumarte a ser tutorx, anotate acá!
                </p>
              </div>
            </div>
          </div>

          {/* BECAS DEL CECEN */}
          <div className="row">

            <div className="col-md-12 col-xs-12">
              FORMULARIO
              
            </div>
            
           
            
          </div>

         
        </div>
      </div>
    </body>
  );
};

export default Finales;
