import react, { useState, useEffect } from "react";
import Header from "../components/Header";
import Swal from 'sweetalert2'

const RegistroUsuario = () => {

    const API_URL = 'https://admin.cecen.ar/api/'
    const [nombre, setNombre] = useState("")
    const [apellido, setApellido] = useState("")
    const [dni, setDni] = useState("")
    const [password, setPassword] = useState("")
    const [email, setEmail] = useState("")
    const [lu, setLu] = useState("")
    const [carrera, setCarrera] = useState(0)
    const [cbc, setCbc] = useState(false)
    const [info, setInfo] = useState(false)

    const [carreras, setCarreras] = useState([])


    const [successRegister, setSuccessRegister] = useState(false)


    useEffect(() => {
        const url = API_URL+"dame_carreras";

        async function fetchData() {
            try {
                const response = await fetch(url);
                const json = await response.json();
                setCarreras(json)

            } catch (error) {
                console.log("error", error);
            }
        };

        fetchData();
    }, []);

    const triggerAlert = (type, textButton, titleText, contentText="") => {
        Swal.fire({
            title: titleText,
            text: contentText,
            icon: type,
            confirmButtonText: textButton
        })    
    }

    const registro = () => {
        
        // Validaciones
        if(nombre==""){
            triggerAlert('error', 'Volver', 'Tenes que completar tu nombre.')
        }else if(apellido==""){
            triggerAlert('error', 'Volver', 'Tenes que completar tu apellido.')
        }else if(dni==""){
            triggerAlert('error', 'Volver', 'Tenes que completar tu DNI.')
        }else if(password==""){
            triggerAlert('error', 'Volver', 'Tenes que completar tu contraseña.')
        }else if(email==""){
            triggerAlert('error', 'Volver', 'Tenes que completar tu email.')
        }else{
            const data = {
                "field-nombre": nombre,
                "field-apellido": apellido,
                "field-dni": dni,
                "field-password": password,
                "field-email": email,
                "field-lu": lu,
                "field-carrera": carrera,
                "field-cbc": cbc,
                "field-info": info
            }
            
    
            fetch(API_URL+'usuario/registro', {
                method: "POST",
                body: JSON.stringify(data),
                headers: { "Content-Type": "application/json" },
            })
                .then(response => response.json())
                .then(response => {
                    setSuccessRegister(true)
                })
        }

    }
    
    return (
        <body className="about-us">
            <Header navStyle="transparent" />

            <div
                className="page-header header-filter header-small"
                data-parallax="true"
                style={{ backgroundImage: `url('https://i.imgur.com/5HlhttN.jpg')` }}
            >
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 col-md-offset-2">
                            <h1 className="title">Registrate</h1>
                        </div>
                    </div>
                </div>
            </div>

            <div className="main main-raised">
                <div className="container">
                    <div className="about-description text-center">
                        <div className="row">
                            <div className="col-md-10 col-md-offset-1" style={{ marginBottom: 40 }}>
                                <h2 style={{fontWeight:900}}>Registrate en MiCECEN</h2>
                                <p style={{fontSize:21, lineHeight:1.2}}>
                                MiCECEN es un sistema que te permite anotarte a las becas de trabajo que otorga el CECEN, al igual que a las becas de apuntes y materiales de estudio, y a los sorteos de lockers. Logearte también te permite ver los apuntes de Biblioteca Digital y cargar los tuyos! Además te brinda un descuento en los almuerzos que compres en nuestros locales.
                                </p>

                                {
                                    successRegister &&
                                    <>
                                        <div class="alert alert-success" role="alert">
                                            <i style={{marginRight:10}} className="fa fa-check"></i>
                                            Gracias por registrarte, podes volver al inicio para iniciar sesión con tu usuario.                                        
                                        </div>
                                        <a href="/" class="btn btn-success">Volver</a>

                                    </>
                                }
                                {
                                    !successRegister &&
                                    <>
                                    
                                        <div className="form-element">
                                            <label for="field-nombre"></label>
                                            <input onChange={(e) => setNombre(e.target.value)} required placeholder="Ingresá tu nombre" name="field-nombre" className="form-control" type={'text'} />
                                        </div>
                                        <div className="form-element">
                                            <label for="field-nombre"></label>
                                            <input onChange={(e) => setApellido(e.target.value)} placeholder="Ingresá tu apellido" name="field-apellido" className="form-control" type={'text'} />
                                        </div>
                                        <div className="form-element">
                                            <label for="field-nombre"></label>
                                            <input onChange={(e) => setDni(e.target.value)} placeholder="Ingresá tu DNI" name="field-dni" className="form-control" type={'number'} />
                                        </div>
                                        <div className="form-element">
                                            <label for="field-password"></label>
                                            <input onChange={(e) => setPassword(e.target.value)} placeholder="Ingresá una contraseña" name="field-password" className="form-control" type={'password'} />
                                        </div>
                                        <div className="form-element">
                                            <label for="field-nombre"></label>
                                            <input onChange={(e) => setEmail(e.target.value)} placeholder="Ingresá tu email" name="field-email" className="form-control" type={'email'} />
                                        </div>
                                        <div className="form-element" style={{ marginTop: 15, textAlign: 'left' }}>
                                            <label for="field-cbc">¿Estás cursando el CBC?</label>
                                            <input onChange={(e) => setCbc(e.target.checked)} style={{ marginLeft: 10 }} placeholder="" name="field-cbc" className="" type={'checkbox'} />
                                        </div>
                                        {
                                            !cbc && 
                                                <>
                                                    <div className="form-element">
                                                        <label for="field-lu"></label>
                                                        <input onChange={(e) => setLu(e.target.value)} placeholder="Ingresá tu Libreta Universitaria (Ej: xxx/yy)" name="field-lu" className="form-control" type={'text'} />
                                                    </div>
                                                    <div className="form-element" style={{ marginTop: 20, textAlign: 'left' }}>
                                                        {carreras != [] &&
                                                            <select
                                                                onChange={(c) => setCarrera(c.target.value)}
                                                                name="field-carreras"
                                                                className="form-control"
                                                                style={{ width: '100%', height: '50px' }} data-style="btn btn-round" title="Elegí la carrera" >
                                                                <option value="#">Elegí la carrera</option>

                                                                {
                                                                    carreras.map((c) => { return <option value={c.id} key={c.id}>{c.nombre}</option> })
                                                                }

                                                            </select>
                                                        }
                                                    </div>
                                                </>
                                        }
                                        
                                        <div className="form-element" style={{ marginTop: 15, textAlign: 'left' }}>
                                            <label for="field-info">¿Te gustaría recibir novedades?</label>
                                            <input onChange={(e) => setInfo(e.target.checked)} style={{ marginLeft: 10 }} placeholder="" name="field-info" className="" type={'checkbox'} />
                                        </div>
                                        <div className="form-element" style={{ marginTop: 15, textAlign: 'left' }}>
                                            <button onClick={registro} style={{ width: '100%' }} type="button" className={"btn btn-info"}>Registrate</button>
                                        </div>
                                    </>
                                }           
                            </div>
                        </div>
                    </div>




                </div>



            </div>
        </body>
    );
};

export default RegistroUsuario;
