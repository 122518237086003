import react from "react";
import Header from "../components/Header";

const Locales = () => {
  return (
    <body className="about-us">
      <Header navStyle="transparent" />

      <div
        className="page-header header-filter header-small"
        data-parallax="true"
        style={{ backgroundImage: `url('assets/img/locales/k2.jpg')` }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-md-offset-2">
              <h1 className="title">Nuevos Locales y Servicios</h1>
            </div>
          </div>
        </div>
      </div>

      <div className="main main-raised">
        <div className="container">
          <div className="about-description text-center">
            <div className="row">
              <div className="col-md-10 col-md-offset-1">
                <p className="description cecen-texto">
                El Nuevo CECEN te da la bienvenida en la vuelta a la presencialidad con nuevos locales producto de reformas y acondicionamientos que venimos llevando a cabo desde antes que empiece la pandemia. Desde fotocopiadoras y librerías hasta kioscos y bares en ambos pabellones con comida al costo, ahora también tenemos un NUEVO LOCAL en el 0+infinito. Todos estos espacios están atendidos por estudiantes que perciben una beca por esa actividad, se eligen a través de una convocatoria abierta periódica según necesidad económica y dicha selección está a cargo de une trabajadore social.
                También se otorgan becas de apuntes y útiles, y materiales de laboratorio, se realizan préstamo de guardapolvos, calculadoras, equipos de mate y ahora también cargadores! Además de tener productos al costo para las meriendas y desayunos dentro de la facultad. Todas las convocatoria del Nuevo CECEN son abiertas y se ganan por sorteo. 
                </p>
              </div>
            </div>
          </div>

          {/* COMISIONES */}
          <div className="row" style={{marginTop: 40, padding: '0px 10px'}}>
          <h3 className="title">Dónde encontrar los productos y servicios del Nuevo CECEN</h3>

            <div className="row"> 
              <div className="col-md-4 col-xs-12">
                <div className="localBox">
                  <div className="imgBox" style={{backgroundImage: "url('assets/img/locales/nuevoBar1.jpg')", backgroundPosition: 'top center'}}></div>
                  <div className="descBox">
                    <h3>Nuevo Bar Pab. 1</h3>
                    <p>Está en la entrada del pabellón 1. En este local se ofrecen desayunos y meriendas al costo, almuerzos, fruta, café, agua caliente, y también fotocopias o impresiones en baja cantidad. También vas a poder encontrar microondas y horno eléctrico.</p>
                  </div>
                  <div className="opcionesBox">
                    <ul>
                      <li>
                        <i className="fas fa-clock"></i> De 9am a 16hs
                      </li>
                      <li>
                        <i className="fas fa-sticky-note"></i> Mirá la lista de precios
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-xs-12">
                <div className="localBox">
                  <div className="imgBox" style={{backgroundImage: "url('assets/img/locales/0infbar.jpg')", backgroundPosition: 'top center'}}></div>
                  <div className="descBox">
                    <h3>Kiosco-café 0+Inf</h3>
                    <p>Dentro del 0+inf. Acá vas a poder encontrar café, agua caliente, golosinas, galletitas, snacks, bebidas, productos de cuidado personal y helados. </p>
                  </div>
                  <div className="opcionesBox">
                    <ul>
                      <li>
                        <i className="fas fa-clock"></i> De 9am a 20hs
                      </li>
                      <li>
                        <i className="fas fa-sticky-note"></i> Mirá la lista de precios
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-md-4 col-xs-12">
                <div className="localBox">
                  <div className="imgBox" style={{backgroundImage: "url('assets/img/locales/noriega.jpg')"}}></div>
                  <div className="descBox">
                    <h3>Fotocopiadora y librería</h3>
                    <p>En el entrepiso del Pabellón 1. Este espacio se ubica en el espacio de estudio de la Biblioteca Noriega y cuenta con aire acondicionado. Los préstamos de calculadora, cargadores y mates se otorgan acá. Además podés hacer impresiones en blanco y negro o color de tus informes de laboratorios, libros, guias de las materias, etc. También encontrás todos los objetos de librería que necesites, agua caliente y una máquina de Nescafé!</p>
                  </div>
                  <div className="opcionesBox">
                    <ul>
                      <li>
                        <i className="fas fa-clock"></i> De 9am a 16hs
                      </li>
                      <li>
                        <i className="fas fa-sticky-note"></i> Mirá la lista de precios
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="row"> 
              <div className="col-md-4 col-xs-12">
                <div className="localBox">
                  <div className="imgBox" style={{backgroundImage: "url('assets/img/locales/k2.jpg')"}}></div>
                  <div className="descBox">
                    <h3>Kiosco Pab. 2</h3>
                    <p>Está en planta baja del pabellón 2. Acá se venden golosinas, galletitas, snacks, bebidas, productos de cuidado personal, helados y agua caliente.</p>
                  </div>
                  <div className="opcionesBox">
                    <ul>
                      <li>
                        <i className="fas fa-clock"></i> De 9am a 20hs
                      </li>
                      <li>
                        <i className="fas fa-sticky-note"></i> Mirá la lista de precios
                      </li>
                    </ul>
                  </div>
                </div>
              </div>


              <div className="col-md-4 col-xs-12">
                <div className="localBox">
                  <div className="imgBox" style={{backgroundImage: "url('assets/img/locales/lib2.jpg')"}}></div>
                  <div className="descBox">
                    <h3>Librería Pab. 2</h3>
                    <p>Está en planta baja del pabellón 2. Los préstamos de guardapolvos, calculadora, cargadores y mate se otorgan en este local. Además vas a poder comprar cuadernos y todos los útiles que necesites para la cursada. También se venden materiales de laboratorio y artículos de jardín de infantes.</p>
                  </div>
                  <div className="opcionesBox">
                    <ul>
                      <li>
                        <i className="fas fa-clock"></i> De 9am a 20hs
                      </li>
                      <li>
                        <i className="fas fa-sticky-note"></i> Mirá la lista de precios
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-md-4 col-xs-12">
                <div className="localBox">
                  <div className="imgBox" style={{backgroundImage: "url('assets/img/locales/f2.jpg')"}}></div>
                  <div className="descBox">
                    <h3>Fotocopiadora Pab. 2</h3>
                    <p>Está en planta baja del pabellón 2. En este local vas a poder hacer impresiones en blanco y negro o color de tus informes de laboratorios, libros, guias de las materias, etc, y todas las fotocopias que necesites.</p>
                  </div>
                  <div className="opcionesBox">
                    <ul>
                      <li>
                        <i className="fas fa-clock"></i> De 9am a 16hs
                      </li>
                      <li>
                        <i className="fas fa-sticky-note"></i> Mirá la lista de precios
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="row"> 

              <div className="col-md-4 col-xs-12">
                <div className="localBox">
                  <div className="imgBox" style={{backgroundImage: "url('assets/img/locales/.jpg')"}}></div>
                  <div className="descBox">
                    <h3>Bar Pab. 2</h3>
                    <p>Está en el primer piso del pabellón 2. Acá vas a encontrar desayunos y meriendas al costo, almuerzos, fruta, café y agua caliente.</p>
                  </div>
                  <div className="opcionesBox">
                    <ul>
                      <li>
                        <i className="fas fa-clock"></i> ¡Abre próximamente!
                      </li>
                      {/* <li>
                        <i className="fas fa-sticky-note"></i> Mirá la lista de precios
                      </li> */}
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-md-4 col-xs-12">
                <div className="localBox">
                  <div className="imgBox" style={{backgroundImage: "url('assets/img/locales/sum.jpg')"}}></div>
                  <div className="descBox">
                    <h3>Sum Pab. 2</h3>
                    <p>Está en el primer piso del pabellón 2. Este es un espacio de estudio, donde además vas a poder encontrar horno eléctrico, microondas, máquina para cargar la sube, enchufes y feria de apuntes (traé los que ya no usas!). Además acá se reúne el Remando en el CBC, donde estudiantes avanzades organizan tutorías para estudiantes de CBC.</p>
                  </div>
                  <div className="opcionesBox">
                    <ul>
                      <li>
                        <i className="fas fa-clock"></i> De 9am a 20hs
                      </li>
                      <li>
                        <i className="fas fa-sticky-note"></i> Mirá la lista de precios
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>


          </div>
        </div>
      </div>
    </body>
  );
};

export default Locales;
