import react, {useState} from "react";
import Header from "../components/Header";
import { Link, animateScroll as scroll } from "react-scroll";

const InfoGeneral = () => {

    const [ visible, setVisible ] = useState("")

    return (
        <body className="about-us">
            <Header navStyle="transparent" />
            <div
                className="page-header header-filter header-small"
                data-parallax="true"
                style={{ backgroundImage: `url('https://www.argentina.gob.ar/sites/default/files/_dsc5325.jpg')` }}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 col-md-offset-2">
                            <h1 className="title">Información General</h1>
                        </div>
                    </div>
                </div>
            </div>

            <div className="main main-raised">
                <div className="container">
                    <div className="about-description text-center">
                        <div className="row">

                            {/* Ingresantes */}
                            <div className="col-md-4 col-xs-12">
                                <div className="card" style={{ padding: 0 }}>
                                    <div className={'card-content content-success bkg-violeta'}>
                                        <h3 className="category-social">
                                            <i className={'fa fa-info'}></i> Ingresantes
                                        </h3>
                                        <p className="card-description">¿Cómo me inscribo a la carrera?, inscripción a materias, exámenes y más.</p>
                                        <div className='card-button-home'>
                                            <Link
                                                smooth={true}
                                                offset={0}
                                                duration={400}
                                                to="infoContent"
                                                onClick={() => setVisible("ingresantes")}
                                                className="animate__animated animate__bounce downArrow"
                                            >
                                                <i style={{ paddingRight: '5px' }} className='fas fa-external-link-alt'></i>Ver más
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Avanzades */}
                            <div className="col-md-4 col-xs-12">
                                <div className="card" style={{ padding: 0 }}>
                                    <div className={'card-content content-success bkg-verde'}>
                                        <h3 className="category-social">
                                            <i className={'fa fa-graduation-cap'}></i> Estudiantes Avanzades
                                        </h3>
                                        <p className="card-description">Becas de investigación, concursos, tesis y más.</p>
                                        <div className='card-button-home'>
                                            <Link
                                                smooth={true}
                                                offset={0}
                                                duration={400}
                                                to="infoContent"
                                                onClick={() => setVisible("avanzades")}
                                                className="animate__animated animate__bounce downArrow"
                                            >
                                                <i style={{ paddingRight: '5px' }} className='fas fa-external-link-alt'></i>Ver más
                                            </Link>                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Calendario */}
                            <div className="col-md-4 col-xs-12">
                                <div className="card" style={{ padding: 0 }}>
                                    <div className={'card-content content-success bkg-azul'}>
                                        <h3 className="category-social">
                                            <i className={'fa fa-calendar'}></i> Calendario académico
                                        </h3>
                                        <p className="card-description">Todas las fechas importantes que necesitas saber.</p>
                                        <div className='card-button-home'>
                                            <Link
                                                smooth={true}
                                                offset={0}
                                                duration={400}
                                                to="infoContent"
                                                onClick={() => setVisible("calendario")}
                                                className="animate__animated animate__bounce downArrow"
                                            >
                                                <i style={{ paddingRight: '5px' }} className='fas fa-external-link-alt'></i>Ver más
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="row">

                            {/* Derechos */}
                            <div className="col-md-push-2 col-md-4 col-xs-12">
                                <div className="card" style={{ padding: 0 }}>
                                    <div className={'card-content content-success bkg-azul'}>
                                        <h3 className="category-social">
                                            <i className={'fa fa-users'}></i> Derechos Estudiantiles
                                        </h3>
                                        <p className="card-description">Una recopilación de las reglamentaciones existentes.</p>
                                        <div className='card-button-home'>
                                            <Link
                                                smooth={true}
                                                offset={0}
                                                duration={400}
                                                to="infoContent"
                                                onClick={() => setVisible("derechos")}
                                                className="animate__animated animate__bounce downArrow"
                                            >
                                                <i style={{ paddingRight: '5px' }} className='fas fa-external-link-alt'></i>Ver más
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* guia de tramites */}
                            <div className="col-md-push-2 col-md-4 col-xs-12">
                                <div className="card" style={{ padding: 0 }}>
                                    <div className={'card-content content-success bkg-violeta'}>
                                        <h3 className="category-social">
                                            <i className={'fa-solid fa-book'}></i> Guía de trámites
                                        </h3>
                                        <p className="card-description">Información útil sobre certificados, equivalencias, simultaneidades, etc.</p>
                                        <div className='card-button-home'>
                                            <Link
                                                smooth={true}
                                                offset={0}
                                                duration={400}
                                                to="infoContent"
                                                onClick={() => setVisible("guia")}
                                                className="animate__animated animate__bounce downArrow"
                                            >
                                                <i style={{ paddingRight: '5px' }} className='fas fa-external-link-alt'></i>Ver más
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row" id="infoContent" style={{margin: '20px 0px'}}>

                            { /* Ingresantes */ }
                            {
                                visible == "ingresantes" && 
                                    <div className="col-xs-12" id="ingresantes">
                                        <h2>Ingresantes</h2>
                                        <h3>¿Cómo me inscribo a la carrera?</h3>
                                        <p>Tenes que enviar una declaración jurada ingresando a <a href="http://inscripciones.exactas.uba.ar/preinscripcion/grado/" target={"_blank"}>http://inscripciones.exactas.uba.ar/preinscripcion/grado/</a> y completar la información de la pagina. Una vez completo descargarlo en PDF que entrega la aplicacion con tu apellido y nombre mas la palabra INSCRIPCIÓN</p> 
                                        <p>Luego completar con un editor de PDF el área destinada a la historia académica del CBC en el archivo PDF que guardaste. Debes consignar código, nombre de la materia, dónde la cursaste (CBC, UBA XXI, CNBA, ILSE, CPEL), cuatrimestre o período de aprobación y su calificación.</p>
                                        <p>Si el CBC te aprobó por equivalencia materias, las tenés que mencionar agregando el número de resolución y fecha de otorgamiento (por ej. 03 – Física – Aprobada – Res.CBC 355 – 10/12/2020)</p>
                                        <p>DNI de ambos lados que deberá estar contenido en un sólo archivo pdf. Las imágenes del frente y dorso del DNI tienen que estar horizontal, legibles y en un tamaño normal o grande. El archivo tiene que ser nombrado con el apellido y nombre del estudiante más la palabra “DNI”.</p>
                                        <p>Título secundario legalizado por la UBA. El trámite se realizar por TAD: <a href="https://tramitesadistancia.uba.ar/tramitesadistancia/inicio-publico" target={"_blank"}>https://tramitesadistancia.uba.ar/tramitesadistancia/inicio-publico</a>. El archivo tiene que ser el que te devuelva la UBA (PDF con la legalización y las hojas del título secundario embebidas en el mismo pdf). Renombralo con tu apellido y nombre más la palabra “TITULO”.</p>
                                        <p>En caso de no tener el título secundario se podrá presentar un certificado de título en trámite expedido por tu colegio con fecha actualizada al momento de presentarlo pero será un requisito incompleto que condiciona tu ingreso a la facultad.</p>
                                        <p>En caso que se haya obtenido el título secundario de un colegio extranjero se deberá presentar la conválida del título legalizado por UBA a través de TAD. No se acepta el título secundario del país de origen.</p>
                                        <p>Si iniciaste el trámite por TAD <a target={"_blank"} href="https://tramitesadistancia.uba.ar/">https://tramitesadistancia.uba.ar/</a> </p>
                                    
                                        <h3>Inscripción a materias</h3>
                                        <p>La inscripción de materias cuatrimestrales se hace a través del SIU Guaraní. Entrá a <a href="https://inscripciones.exactas.uba.ar/exactas/" target={"_blank"}>https://inscripciones.exactas.uba.ar/exactas/</a> e ingresá con tu DNI y la contraseña que te dieron al entrar a la Facultad. La inscripción se hace en la pestaña “cursadas”.</p>

                                        <h3>Inscripción a exámenes</h3>
                                        <p>La inscripción a exámenes finales suele hacerse a través del SIU Guaraní, en la pestaña “Exámenes”, aunque algunas materias organizan su propia inscripción. Consultale al profesor o profesora de la materia para corroborar.</p>
                                        
                                    
                                    </div>
                            }

                            { /* Avanzades */ }
                            {
                                visible == "avanzades" && 
                                    <div className="col-xs-12" id="avanzades">
                                        <h2>Estudiantes Avanzades</h2>
                                        <h3>Becas de investigación</h3>
                                        <p>En la actualidad existen distintas becas de inicio a la investigación:</p> 
                                        <ul>
                                            <li>
                                                <strong><a href="https://exactas.uba.ar/becas-de-investigacion/becas-uba/" target={"_blank"}>Beca estímulo UBA:</a></strong>
                                                &nbsp; Es una beca que pide 20 horas semanales de trabajo en un laboratorio y es exclusiva (solo es compatible con 1 cargo de ayudante de 2da). En la actualidad el estipendio es de $22.800.
                                                <br /> <strong>Requisitos: tener el 50% de la carrera aprobada (incluyendo CBC)</strong>
                                            </li>
                                            <li>
                                                <strong><a href="https://exactas.uba.ar/becas-de-investigacion/becas-cin/" target={"_blank"}>Beca de Estímulo a las Vocaciones Científicas:</a></strong>
                                                &nbsp; Esta beca del Consejo Interuniversitario Nacional tiene un estipendio de $6100.
                                                <br /> <strong>Requisitos: tener el 50% de la carrera aprobada (incluyendo CBC) y un promedio mayor a 6</strong>
                                            </li>
                                            <li>
                                                <strong>Beca de inicio a la investigación de Computación:</strong>
                                                &nbsp; El Departamento de Computación tiene un programa de becas de iniciación a  la investigación con un estipendio equivalente al de un ayudante de primera. Las inscripciones abren al inicio de cada Cuatrimestre.
                                                <br /> <strong>Requisitos: tener aprobadas 3 materias del tercer año de la carrera.</strong>
                                            </li>
                                        </ul>
                                    
                                        <h3>Bolsa de tesis para Biología</h3>
                                        <p>La comisión de carrera de biología tiene una bolsa de tesis encontrala acá: <a href="https://cccb.exactas.uba.ar/tesis-de-licenciatura/bolsa-de-oferta-de-tesis/" target={"_blank"}>https://cccb.exactas.uba.ar/tesis-de-licenciatura/bolsa-de-oferta-de-tesis/</a></p>

                                        <h3>Concursos</h3>
                                        <p>Exactas es una de las pocas facultades que ofrece concursos para cargos de ayudantes de segunda rentados.</p>
                                        <p>Todos los años hay concursos regulares de todos los departamentos, el único requisito es tener 4 materias aprobadas de la carrera (sin incluir CBC).</p>
                                        <p>Podés encontrar los próximos concursos acá: <a href="https://exactas.uba.ar/institucional/concursos/aux-interinos/" target={"_blank"}>https://exactas.uba.ar/institucional/concursos/aux-interinos/</a></p>
                                        
                                    
                                    </div>
                            }

                            { /* Calendario */ }
                            {
                                visible == "calendario" && 
                                    <div className="col-xs-12" id="calendario">
                                        <h2>Calendario Académico</h2>
                                        <a href="https://exactas.uba.ar/calendario-academico/">Acá podés encontrar el calendario académico del 2024</a>
                                        </div>
                            }

                            { /* Avanzades */ }
                            {
                                visible == "derechos" && 
                                    <div className="col-xs-12" id="derechos">
                                        <h2>Derechos estudiantiles</h2>
                                        <p>Como organo de representación gremial, el CECEN es un espacio donde podés hacer reclamos por cualquier derecho que no se cumpla y se va a trabajar para hacerlos cumplir.</p>
                                        <p>Te dejamos los derechos estudiantiles que existen hoy y resoluciones importantes: <a href="https://exactas.uba.ar/institucional/la-facultad/digesto-normativo/" target={"_blank"}>https://exactas.uba.ar/institucional/la-facultad/digesto-normativo/</a></p>
                                    </div>
                            }

                            { /* Guia */ }
                            {
                                visible == "guia" && 
                                    <div className="col-xs-12" id="guia">
                                        <h2>Guía de trámites estudiantiles</h2>
                                        <h3>Certificados</h3>
                                        <ul>
                                            <li><strong>Alumno regular:</strong> Para solicitar el certificado tenés que entrar al Sistema de Inscripciones de la Facultad. Apretá la pestaña Trámites y luego Nueva solicitud.</li>
                                            <li><strong>Certificado de examen:</strong> El día del examen podés pedirselo a tu docente.</li>
                                            <li><strong>Materias aprobadas:</strong> Para solicitar el certificado tenés que entrar al Sistema de Inscripciones de la Facultad. Apretá la pestaña Trámites y luego Nueva solicitud».</li>
                                            <li><strong>Título en trámite:</strong> Se realiza a través del <a href="https://tramitesadistancia.uba.ar/tramitesadistancia/tad-publico" target={"_blank"}>sistema de Trámites a Distancia de la Universidad de Buenos Aires (TAD-UBA)</a>. Ingresando a solicitud de expedición de diplomas.</li>
                                            <li><strong>Pases y simultaneidades:</strong> Si venís de otra carrera universitaria, ya sea de la UBA u otra institución, podés iniciar el trámite de pase o simultaneidad para que te avalen las materias aprobadas correspondientes. Acercate a Departamento de Estudiantes para más información.</li>

                                        </ul>

                                        <p>Como organo de representación gremial, el CECEN es un espacio donde podés hacer reclamos por cualquier derecho que no se cumpla y se va a trabajar para hacerlos cumplir.</p>
                                        <p>Te dejamos los derechos estudiantiles que existen hoy y resoluciones importantes: <a href="https://exactas.uba.ar/institucional/la-facultad/digesto-normativo/" target={"_blank"}>https://exactas.uba.ar/institucional/la-facultad/digesto-normativo/</a></p>
                                    </div>
                            }

                        </div>


                    </div>
                </div>
            </div>
        </body>
    );
};

export default InfoGeneral;
