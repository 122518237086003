import React from "react";

export function BotoneraSingle(props) {
  return <div className="card" style={{ padding: 0 }}>
    <div className={'card-content content-success '+props.bkg}>
      <h3 className="category-social">
        <i className={'fa fa-'+props.icon}></i> { props.title }
      </h3>
      <p className="card-description">{props.content}</p>
      <div className='card-button-home'>
        {
          props.proximamente && <h6 style={{marginBottom: 0, fontWeight: 900}}><i style={{marginRight: 5}} className="fa fa-clock"></i>Próximamente</h6>
        }
        {
          props.proximamente==undefined &&
            <a href={ props.link }><i style={{ paddingRight: '5px' }} className='fas fa-external-link-alt'></i>Entrar</a>
        }
        
      </div>
    </div>
  </div>;
}
