import React, {useContext} from "react";
import { UserContext } from "../../contexts/UserContext";
import { BotoneraSingle } from "./BotoneraSingle";

export function BotoneraHome() {

  const { user, setUser } = useContext(UserContext);

  return (
    <div className="row">
      {
        user && 
        <>
          <div className="col-md-4">
            <BotoneraSingle
              title="Biblioteca Digital"
              content="Biblioteca colaborativa donde vas a poder encontrar apuntes, parciales, finales, libros y más!"
              bkg="bkg-azul"
              link="bibliotecadigital"
              icon="book"
            />
          </div>
          <div className="col-md-4">
            <BotoneraSingle
              title="Pedí tu locker"
              content=" El CECEN tiene lockers en el Pabellón 1, en el 2 y en Industrias. Los lockers se reasignan por sorteo entre todes les estudiantes que se anoten."
              bkg="bkg-violeta"
              link="pedido-lockers"
              icon="key"
            />
          </div>
          <div className="col-md-4">
            <BotoneraSingle
              title="Solicitá una beca de trabajo"
              content="El CECEN ofrece becas de trabajo de 20hs semanales en alguno de los 7 locales. Es una opción compatible con la cursada y se asigna según vulnerabilidad económica."
              bkg="bkg-verde"
              icon="money"
              link="becas-trabajo"
            />
          </div>
          <div className="col-md-4">
            <BotoneraSingle
              title="Solicitá una beca de apuntes o material de estudio"
              content="El CECEN ofrece becas de apuntes y material de estudio para utilizar en los locales del Nuevo CECEN."
              bkg="bkg-azul"
              link="becas-apuntes"
              icon="pencil"
            />
          </div>
          <div className="col-md-4">
            <BotoneraSingle
              title="Solicitá una beca de materiales de laboratorio"
              content="El CECEN ofrece becas de materiales de Laboratorio para utilizar en los locales del Nuevo CECEN."
              bkg="bkg-violeta"
              link="becas-laboratorio"
              icon="vial"
            />
          </div>
        </>
      }
      
      <div className="col-md-4">
        <BotoneraSingle
          title="Becas"
          content="Encontrá toda la info sobre las becas que otorga el Nuevo CECEN, la facultad, la UBA y el gobierno nacional."
          bkg="bkg-verde"
          link="becas"
          icon="graduation-cap"
        />
      </div>
      <div className="col-md-4">
        <BotoneraSingle
          title="Nuevos Locales y Servicios"
          content="Acá vas a poder ver todos los locales que tiene el Nuevo CECEN y lo que vas a encontrar en cada uno."
          bkg="bkg-azul"
          icon="store"
          link="locales"
        />
      </div>

      <div className="col-md-4">
        <BotoneraSingle
          title="Prepará finales"
          content="En este espacio vas a poder preparar los finales de tus primeras materias junto a estudiantes más avanzades."
          bkg="bkg-violeta"
          icon="pencil-alt"
          link="finales"
        />
      </div>
      <div className="col-md-4">
        <BotoneraSingle
          title="Más información"
          content="Inscripciones a ingresantes, información útil para estudiantes avanzades, calendario académico, guía de trámites, derechos estudiantiles, etc."
          bkg="bkg-verde"
          link="infogeneral"
          icon="fa-solid fa-circle-info"
        />
      </div>
      <div className="col-md-4">
        <BotoneraSingle
          title="El Nuevo CECEN en el CBC"
          content="Desde el CBC con vos, acá vas a poder ver todo lo que necesitas saber del CBC."
          bkg="bkg-verde"
          link="cbc"
          icon="graduation-cap"
        />
      </div>
    </div>
  );
}
