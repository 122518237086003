import react, { useEffect, useContext } from "react";
import Header from "../components/Header";
import { UserContext }  from "../contexts/UserContext"

const CecenInfo = () => {
  const { user } = useContext(UserContext);

  useEffect(() => {
    console.log(user)
  }, [])


  return (
    <body className="about-us">
      <Header navStyle="transparent" />
      
      <div
        className="page-header header-filter header-small"
        data-parallax="true"
        style={{ backgroundImage: `url('assets/img/nuevocecen.jpg')` }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-md-offset-2">
              <h1 className="title">¿Qué es el Nuevo CECEN?</h1>
            </div>
          </div>
        </div>
      </div>

      <div className="main main-raised">
        <div className="container">
          <div className="about-description text-center">
            <div className="row">
              
              <div className="col-md-10 col-md-offset-1">
                <p className="description cecen-texto">
                  
                  El{" "}
                  <strong>
                    nuevo CECEN (Centro de Estudiantes de Ciencias Exactas y
                    Naturales) es el órgano gremial que tenemos les estudiantes
                    de la FCEyN
                  </strong>
                  . Un ámbito en donde nos organizamos para velar por nuestros
                  derechos estudiantiles y conseguir nuevos, para defender la
                  educación pública gratuita, masiva y de calidad, para pelear
                  por una ciencia al servicio del pueblo y para aportar a la
                  construcción de un país (y un mundo) más inclusivo. Además,
                  permite que les estudiantes nos conectemos entre nosotres y
                  generemos redes.
                </p>
                <p className="description cecen-texto">
                  El{" "}
                  <strong>
                    nuevo CECEN es un espacio que construimos entre todes
                  </strong>{" "}
                  y tiene sus propios encuentros periódicos. Podes participar de
                  comisiones directivas, asambleas y comisiones de temas
                  específicos y de todas las actividades que surjan de estos
                  encuentros.
                </p>
              </div>
            </div>
          </div>

          {/* COMISIONES */}
          <div className="row">
            <div className="container">
              <div className="about-services features-2">
                <div className="row">
                  <div className="col-md-8 col-md-offset-2 text-center">
                    <h2 className="title">Comisiones</h2>
                    <p className="description cecen-texto">
                      <strong>
                        Las comisiones del Nuevo CECEN son abiertas a todes les
                        estudiantes de la facultad
                      </strong>{" "}
                      que estén interesades en discutir y activar sobre las
                      diferentes problemáticas que vemos dentro y fuera de la
                      facultad. Para sumarte escribinos a las redes del CECEN y
                      Sumate a construir una facultad y un país más inclusivos.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6">
              {/* COLUMNA IZQUIERDA */}
              {/* CARD AMBIENTE */}
              <div className="card" style={{ padding: 0 }}>
                <div className="card-content content-success bkg-verde">
                  <h5 className="category-social">
                    <i className="fa fa-tree"></i> Comisión Ambiente
                  </h5>
                  <p className="card-description">
                    La comisión ambiente es el espacio del Nuevo CECEN en el que
                    les estudiantes nos organizamos, discutimos, generamos
                    actividades y documentos sobre las problemáticas
                    ambientales. En la comisión buscamos formarnos en las
                    diferentes problemáticas con una perspectiva socioambiental
                    e interdisciplinaria ya que entendemos que los problemas
                    ambientales son multicausales. Además creemos que no puede
                    hablarse del ambiente sin tener en cuenta al pueblo que lo
                    habita y que un país más justo y desarrollado no existe sin
                    el cuidado del ambiente y un uso consciente de los recursos
                    naturales.
                  </p>
                </div>
              </div>
              {/* CARD Diversidades y Disidencias */}
              <div className="card" style={{ padding: 0 }}>
                <div className="card-content content-warning bkg-violeta">
                  <h5 className="category-social">
                    <i className="fa fa-tree"></i> Comisión Diversidades y
                    Disidencias
                  </h5>
                  <p className="card-description">
                    En la Comisión de Diversidad y Disidencias del CECEN nuestro
                    objetivo es funcionar como lugar de encuentro y de lucha
                    frente a las problemáticas de las personas fuera de la
                    heteronorma. Nuestra militancia es siempre a la par del
                    feminismo y entendiendo, como dijo Jáuregui, que en una
                    sociedad que nos educa para la vergüenza, el orgullo es una
                    respuesta política.
                  </p>
                </div>
              </div>
              {/* CARD Encuentro y Divulgación */}
              <div className="card" style={{ padding: 0 }}>
                <div className="card-content content-warning bkg-verde">
                  <h5 className="category-social">
                    <i className="fas fa-laptop"></i> Comisión Encuentro y
                    Divulgación
                  </h5>
                  <p className="card-description">
                    Durante la pandemia se vió fuertemente la falta de
                    acercamiento entre estudiantes que antes se daba en la
                    cotidianeidad principalmente en los espacios del Centro de
                    Estudiantes, como la biblioteca Noriega, el Bar del Pabellón
                    2 y el SUM. Pero que también para algunas fechas
                    particulares se daban en la Noche de Juegos, Cine Debate y
                    las fiestas que organizaban las comisiones por carrera.
                    Desde el Nuevo CECEN tomamos la tarea de seguir cumpliendo
                    ese rol de acercamiento y contención de les estudiantes aún
                    si no pudiéramos ir a la facultad, desde este espacio se
                    creó www.cafenoriega.com.ar para nuclear diferentes
                    actividades surgidas de distintos grupos de estudiantes de
                    nuestra FCEN.
                  </p>
                </div>
              </div>
            </div>
            {/* FIN COLUMNA IZQUIERDA */}
            {/* COLUMNA DERECHA */}
            <div className="col-md-6">
              {/* CARD Mujeres */}
              <div className="card" style={{ padding: 0 }}>
                <div className="card-content content-primary bkg-violeta">
                  <h5 className="category-social">
                    <i className="fas fa-venus-double"></i> Comisión Mujeres,
                    Lesbianas, Bisexuales, Travestis, Trans, No Binaries y
                    Asexuales
                  </h5>
                  <p className="card-description">
                    Esta comisión es el espacio donde nos organizamos para
                    luchar por nuestros derechos. Ya logramos el aborto legal y
                    el cupo laboral trans. Ahora seguimos discutiendo sobre los
                    derechos sexuales y reproductivos, violencias hacia el
                    colectivo LGBTIQA+ y mujeres cis y cualquier tema que nos
                    interese! Está fuertemente articulada con la comisión de
                    Diversidades y Disidencias.
                  </p>
                </div>
              </div>
              {/* CARD clases virtuales */}
              <div className="card" style={{ padding: 0 }}>
                <div className="card-content content-primary bkg-azul">
                  <h5 className="category-social">
                    <i className="fa fa-tree"></i> Clases virtuales y Nuevos
                    Derechos
                  </h5>
                  <p className="card-description">
                    La comisión de clases virtuales es un espacio que se creó
                    durante la pandemia de COVID con el fin de tener un lugar
                    desde el CECEN para organizarnos frente a la virtualización
                    de la educación y buscar las mejores soluciones para la
                    inclusión de todes les estudiantes que sufrían por falta de
                    herramientas para acceder a las clases virtuales y también
                    para garantizar que no se vulnere ningún derecho
                    estudiantil. Desde este espacio también nos juntamos a
                    pensar la mejor forma de volver a una presencialidad que no
                    excluya a ningún estudiante.
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* AUTORIDADES */}
         

          {/* COMISIÓN DIRECTIVA */}
          <div className="row">
            <div className="container">
              <div className="about-services features-2">
                <div className="row">
                  <div className="col-md-8 col-md-offset-2 text-center">
                    <h2 className="title">Comisión Directiva</h2>
                    <p className="description cecen-texto">
                      Todos los años les estudiantes votamos la presidencia y
                      vicepresidencia del CECEN. En esas elecciones también se
                      conforma la Comisión Directiva compuesta por 11 vocales de
                      las diferentes listas que se presenten y obtengan una
                      cantidad mínima de votos según la proporción de los
                      mismos. Este órgano discute y vota posicionamientos,
                      actividades y otros temas del día a día del CECEN. Sus
                      reuniones son abiertas y también funciona por medio de una
                      lista de mails. En 2020 y 2021 no se pudieron realizar
                      elecciones a causa de la pandemia y los mandatos fueron
                      prorrogados hasta que estuvieranestén las condiciones
                      dadas para llevarlas adelante.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* ASAMBLEA */}
          <div className="row">
            <div className="container">
              <div className="about-services features-2">
                <div className="row">
                  <div className="col-md-8 col-md-offset-2 text-center">
                    <h2 className="title">Asamblea</h2>
                    <p className="description cecen-texto">
                      Es el máximo lugar de toma de decisiones que tenemos todes
                      les estudiantes de la Facultad. Las asambleas son
                      abiertas, todes podemos acercarnos a decir lo que
                      pensamos, proponer y a votar lo que creamos.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </body>
  );
};

export default CecenInfo;
