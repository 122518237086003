export function InstagramPost(props) {
  return (<div className="col-md-4" key={props.post.id}>
    <div className="card card-blog">
      <div className="card-image">
        <a href="#pablo">
          <img
            className="img"
            src={props.post.media_url} />
          {/* <div className="card-title">Abre el 0+Inf</div> */}
        </a>
      </div>

      <div className="card-content">
        {/* <h6 className="category text-info">
          Conocé el edificio por adentro
        </h6> */}
        <p className="card-description" style={{ whiteSpace: "pre-line" }}>
          {props.post.caption}
        </p>
      </div>
    </div>
  </div>);
}
