import react, { useEffect, useState } from "react";
import { InstagramPost } from "./InstagramPost";

function InstagramFeed() {

  const [instagramPosts, setInstagramPosts] = useState([]);

  const IG_TOKEN = 'IGQVJVTXV3WGlBVW9kUzZALYml1b0ZA6UWY3ZAXVaN20zNHNlNnFsX213cWZA2RXhzdEIyVWpVMmNPQ25UY1RKaDlaRXBVa01pOVhNRGQ0cXo4MDFoNGNXejJuekRLODJtVUh3NWdfYi1QQmc5MkJmV2RPMAZDZD'
  const IG_FIELDS = 'id,username,media_type,media_url,caption'

  useEffect(() => {

    fetch(`https://graph.instagram.com/4520396774753427/media?count=3&fields=${IG_FIELDS}&access_token=${IG_TOKEN}`)
      .then(results => results.json())
      .then(json => getFirstImagePost(json, 3))
      .then(postList => setInstagramPosts(postList))
      .catch((err) => console.log(err));

  }, []);

  const getFirstImagePost = (postList, count = 3) => {
    let finalPostList = postList.data.filter(post => post.media_type == 'IMAGE' || post.media_type == 'CAROUSEL_ALBUM')
    finalPostList = finalPostList.slice(0, count)
    return finalPostList
  }

  return (
    <div id="cards" className="cd-section">
      <div className="section-gray">
        <div className="cards">
          <div className="container">
            <div className="title">
              <h3 style={{ fontSize: '36px' }}>
                <i className="fab fa-instagram"></i>
              </h3>
            </div>
            <div className="row">
              {instagramPosts.map((post) => <InstagramPost key={post.id} post={post} />)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InstagramFeed;

