import react from 'react'

const Resultados = (props) => {

	const ResultadoPantallaGrande = <div className="col-md-12 hidden-xs">
		{/* <h4><small>Teoría de Lenguajes</small></h4> */}

		<div className="table-responsive">
			<table className="table">
				<thead>
					<tr>
						{/* <th className="text-center">#</th> */}
						{/* <th>Categoría</th> */}
						{/* <th>Subido por</th> */}
						<th>Fecha-Cuatrimestre</th>
						<th>Descripción</th>
						<th className="text-right"></th>
					</tr>
				</thead>
				<tbody>

					{
						props.apuntes.map((a) => {
							return <tr>
								{/* <td className="text-center">1</td> */}
								{/* <td>1º Parcial</td> */}
								<td>{a.año} - {a.cuatrimestre}º Cuatrimestre</td>
								<td>{a.descripcion}</td>
								<td className="td-actions text-right">
									<a download target={"_blank"} href={"https://admin.cecen.ar/media/"+a.archivo} className="btn btn-success">
										<i className="material-icons">download</i>
									</a>
								</td>
							</tr>;
						})
					}
				</tbody>
			</table>
		</div>
	</div>;

	console.log(props.apuntes)
	return (
		<>
			<div className="col-md-12">
				<a href="">
					<h4>
						<small><i style={{ fontSize: 12, paddingRight: 10 }} className="material-icons" >keyboard_return</i>Hacer otra búsqueda</small>
					</h4>
				</a>
				<h3 style={{ fontWeight: '900' }}>Resultados</h3>
			</div>

			{ /* Tabla de resultados para Pantalla > 768px */}
			{ResultadoPantallaGrande}

			{ /* Tabla de resultados para Pantalla < 768px */}
			<div className="col-md-12 hidden-sm hidden-md hidden-lg">
				{/* <h4><small>Teoría de Lenguajes222</small></h4> */}

				{
					props.apuntes.map((a) => {
						return <div style={{ margin: '20px 0' }}>
							<span style={{ fontSize: '14px', float: 'left', width: '100%' }}>1° Parcial</span>
							<span style={{ fontSize: '14px', float: 'left', width: '100%', fontWeight: 900 }}>{a.año} - {a.cuatrimestre}º Cuatrimestre</span>
							<span style={{ fontSize: '14px', float: 'left', width: '100%' }}>Tiene resoluciones incompletas</span>
							<button type="button" rel="tooltip" className="btn btn-success">
								<i className="material-icons">download</i>
							</button>
						</div>;
					})
				}

			</div>

		</>
	);
}

export default Resultados;