import react from "react";
import Header from "../components/Header";

const BecasMateriales = () => {
  return (
    <body className="about-us">
      <Header navStyle="transparent" />
      <div
        className="page-header header-filter header-small"
        data-parallax="true"
        style={{ backgroundImage: `url('https://exactas.uba.ar/wp-content/uploads/2019/03/DSC_0061bW.jpg')` }}>
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-md-offset-2">
              <h1 className="title">Becas de Materiales de Estudio</h1>
            </div>
          </div>
        </div>
      </div>

      <div className="main main-raised">
        <div className="container">
          <div className="about-description text-center">
            <div className="row">
              <div className="col-md-10 col-md-offset-1">
                <p className="description cecen-texto">
                El CECEN ofrece becas de apuntes para utilizar en la fotocopiadora del Pabellón 2 o la Noriega en el pabellón 1. También ofrece becas de materiales de estudio para usar en articulos de librería o materiales de laboratorio, que se pueden encontrar en la Librería del Pabellón 2, o en la Noriega del pabellón 1. <br /> Se asignan según vulnerabilidad económica. <br /> La convocatoria abre a principios de cada cuatrimestre, <strong>te vas a poder anotar en este forms:</strong>

                </p>
                <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSeGbi0lYG35NMHqz8brqGVI9Rk2VyazEYc8ht1D9AFFB4XaWA/viewform?embedded=true" width="100%" height="9200" frameborder="0" marginheight="0" marginwidth="0">Cargando…</iframe>
              </div>
            </div>
          </div>


         
        </div>
      </div>
    </body>
  );
};

export default BecasMateriales;
