import react from "react";
import Header from "../components/Header";

const BecasTrabajo = () => {
  return (
    <body className="about-us">
      <Header navStyle="transparent" />
      <div
        className="page-header header-filter header-small"
        data-parallax="true"
        style={{ backgroundImage: `url('https://exactas.uba.ar/wp-content/uploads/2019/03/DSC_0061bW.jpg')` }}>
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-md-offset-2">
              <h1 className="title">Becas de Trabajo</h1>
            </div>
          </div>
        </div>
      </div>

      <div className="main main-raised">
        <div className="container">
          <div className="about-description text-center">
            <div className="row">
              <div className="col-md-12 ">
                <p className="description cecen-texto">
                El CECEN ofrece becas de trabajo de 20hs semanales en alguno de los 7 locales. Es una opción compatible con la cursada y se asigna según vulnerabilidad económica. La convocatoria abre a principios de cada cuatrimestre, te vas a poder anotar en este forms.
                </p>
              <iframe style={{marginTop:30}} src="https://docs.google.com/forms/d/e/1FAIpQLSc-tPTuROlagvWr7ZXAxcO41F5McyXgN25Oe2KETfW348ktZA/viewform?embedded=true" width="100%" height="9300" frameborder="0" marginheight="0" marginwidth="0">Cargando…</iframe>
              </div>
            </div>
          </div>


         
        </div>
      </div>
    </body>
  );
};

export default BecasTrabajo;
